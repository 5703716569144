
.monitor-setting-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gas-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.spacer-div {
    width: 16px;
}
.monitor-dtm-form {
    width: 100px;
}
.update-dtm-btn {
    margin-left: 16px;
}

.divider {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
}

